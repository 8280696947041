// export const geoApiUrl = '/api';
// export const geoApiUrl = 'http://localhost:8080/api';
export const geoApiUrl = 'https://geo.codelis.ch/api';

export type GoogleConfig = {
    apiKey: string,
    mockGoogle: boolean,
};
export const googleConfig: GoogleConfig = {
    apiKey: 'AIzaSyBQ1VzOXpmdii0cZvx-4l5qex9Y-L8tUjY',
    mockGoogle: false,
};
